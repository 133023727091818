import {
  Button,
  Container,
  Card,
  CardContent,
  CardActions,
  CardMedia,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import React, { useEffect, useState } from "react";
import * as Tone from "tone";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function App() {
  const [notes, setNotes] = useState({});
  const [palabras, setPalabras] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [text, setText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    // Fetch the notes JSON file
    fetch("/notes.json")
      .then((response) => response.json())
      .then((data) => setNotes(data))
      .catch((error) => console.error("Error fetching the notes JSON:", error));

    // Fetch the words JSON file
    fetch("/palabras.json")
      .then((response) => response.json())
      .then((data) => setPalabras(data.palabras))
      .catch((error) => console.error("Error fetching the words JSON:", error));

    // Fetch the images JSON file
    fetch("/imagenes.json")
      .then((response) => response.json())
      .then((data) => setImagenes(data.imagenes))
      .catch((error) =>
        console.error("Error fetching the images JSON:", error)
      );
  }, []);

  const generarTextoAleatorio = (numPalabras) => {
    let resultado = [];
    for (let i = 0; i < numPalabras; i++) {
      const palabraAleatoria =
        palabras[Math.floor(Math.random() * palabras.length)];
      resultado.push(palabraAleatoria);
    }
    return resultado.join(" ");
  };

  const playSound = () => {
    const textoAleatorio = generarTextoAleatorio(10); // Generar una cadena de 10 palabras aleatorias
    setText(""); // Limpiar el texto antes de empezar
    setIsPlaying(true);

    const synth = new Tone.Synth().toDestination();
    const now = Tone.now();
    let currentTime = now;

    let index = 0;
    let currentText = "";

    const interval = setInterval(() => {
      if (index < textoAleatorio.length) {
        const char = textoAleatorio[index].toUpperCase();
        currentText += char;
        setText(currentText);

        // Seleccionar una imagen aleatoria
        const imagenAleatoria =
          imagenes[Math.floor(Math.random() * imagenes.length)];
        setCurrentImage(imagenAleatoria);

        if (char !== " " && notes[char]) {
          const note = notes[char];
          const escala = note.escala || note.nota; // Handle both 'escala' and 'nota'
          synth.triggerAttackRelease(escala, note.duracion, currentTime);
          currentTime += 0.5; // Increment time for next note
        } else {
          currentTime += 0.5; // Increment time for a space or undefined note
        }

        index++;
      } else {
        clearInterval(interval);
        setIsPlaying(false);
      }
    }, 500); // Ajusta el intervalo según sea necesario

    // Set a timeout to re-enable the button after the sound has finished playing
    const totalTime = currentTime - now + 0.5; // Additional 0.5 seconds for any delay at the end
    setTimeout(() => {
      if (isPlaying) {
        setIsPlaying(false);
      }
    }, totalTime * 1000); // Convert to milliseconds
  };

  return (
    <Container maxWidth="sm">
      <div className="flex flex-col min-h-screen pt-12">
        <Card sx={{ boxShadow: 10, p: 4 }}>
          <CardMedia
            component="img"
            image="/soniditos.gif"
            alt="Imagen aleatoria"
            sx={{ maxHeight: 300, objectFit: "contain" }}
          />
          <CardContent>
            {isPlaying && (
              <img
                src={currentImage}
                alt="Loading"
                className="h-64 py-4 mx-auto"
                onError={(e) => e.target.src = '/nutria.webp'}
              />
            )}
            <Textarea
              size="lg"
              value={text}
              readOnly
              className="w-full"
              placeholder="Dale a play..."
            />
          </CardContent>
          <CardActions>
            <Button
              color="secondary"
              onClick={playSound}
              variant="contained"
              startIcon={<PlayArrowIcon />}
              fullWidth
              disabled={isPlaying}
            >
              Play
            </Button>
          </CardActions>
        </Card>
      </div>
    </Container>
  );
}

export default App;
